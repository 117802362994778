import gql from "graphql-tag";


export const RECIPE_OVERVIEW_DATA = gql`
  fragment RecipeOverviewData on Recipe {
    id
    name
    book {
        name
        formattedName
    }
    ingredients {
        id
        name
    }
    canMake
    linkedRecipes {
        id
        name
        book {
            name
            formattedName
        }
        pageNumber
    }
    favorite
    pageNumber
  }
`;
export const NEW_RECIPE_OVERVIEW_DATA = gql`
  fragment NewRecipeOverviewData on NewRecipe {
    id
    name
    book {
        name
        formattedName
    }
    ingredients {
        id
        name
    }
    canMake
    linkedRecipes {
        id
        name
        book {
            name
            formattedName
        }
        pageNumber
    }
    favorite
    pageNumber
  }
`;

export const LOAD_SEARCH_TERMS = gql`
{
  searchTerms @client {
    id
    name
    type
    inverted
    minMaxAmount {
        minimum
        maximum
        typeOfMeasurement
    }
  }
  selectedGlasses {
    data
    key
  }
  selectedBuildTypes {
    data
    key
  }
  selectedTypes {
    data
    key
  }
  selectedCocktailCategories {
    data
    key
  }
  selectedBooks {
    data
    key
  }
  minMaxIngredients
  minMaxABV
  minMaxAlcoholicUnits
}
`;

export const ON_FAVORITE = gql`
  mutation onFavorite($id: String!, $favorite: Boolean!) {
    newSetFavorite(id: $id, favorite: $favorite) {
      id
      favorite
    }
  }
`;

export const GET_RECIPES = gql`
query GET_RECIPES($filters: [Filter!], $advancedFilters: AdvancedFilters, $offset: Int!, $limit: Int!) {
  newRecipes(filters: $filters, advancedFilters: $advancedFilters, offset: $offset, limit: $limit) {
    ...NewRecipeOverviewData
  }
  newRecipesLength(filters: $filters, advancedFilters: $advancedFilters, offset: $offset, limit: $limit)
}
  ${NEW_RECIPE_OVERVIEW_DATA}
`;

export const GET_LINKED_RECIPES = gql`
query GET_LINKED_RECIPES($id: String!) {
  linkedRecipes(id: $id) {
    id
    name
    book {
        name
        formattedName
    }
    ingredients {
        id
        name
    }
    canMake
    favorite
    pageNumber
  }
}
`;

export const GET_SEARCH_ITEMS_AND_FILTER_DATA = gql`
query GET_SEARCH_ITEMS {
  newSearchItems {
    text
    type
  }
  glasses
  buildTypes
  types
  cocktailCategories
  newBooks {
    name
  }
}
`;

export const GET_RECIPE_FULL = gql`
query GET_RECIPE_FULL($bookFormattedName: String!, $nameWithoutSpaces: String!) {
  newRecipe(bookFormattedName: $bookFormattedName, nameWithoutSpaces: $nameWithoutSpaces) {
    id
    name
    instructions
    comment
    postDilutionAbv
    alcoholicUnits
    postDilutionVolume
    cocktailCategory
    ingredients {
        id
        name
        amount
        typeOfMeasurement
        bottles
    }
    garnish
    glass
    pageNumber
    servings
    tags {
        id
        name
    }
    book {
        name
        formattedName
    }
  }
  newTags {
    id
    name
  }
}
`;

export const GET_BOOK_NAME = gql`
query GET_BOOK_NAME($book: String!) {
  book(bookAbbreviation: $book) {
    name
  }
}
`;

export const DELETE_TAG = gql`
  mutation deleteTag($recipeId: String!, $tag: String!) {
    newDeleteTag(recipeId: $recipeId, tag: $tag) {
        id
        tags {
            id
            name
        }
    }
  }
`;

export const ADD_TAG = gql`
  mutation addTag($recipeId: String!, $tag: String!) {
    newAddTag(recipeId: $recipeId, tag: $tag) {
        id
        tags {
            id
            name
        }
    }
  }
`;

export const SAVE_COMMENT = gql`
  mutation saveComment($recipeId: String!, $comment: String!) {
    newSaveComment(recipeId: $recipeId, comment: $comment) {
        id
        comment
    }
  }
`;

// Should be equal to ON_CAN_MAKE with the addition of related recipe
export const GET_RECIPE_INGREDIENTS_VIEW = gql`
query GET_RECIPE_INGREDIENTS_VIEW($recipeId: String!) {
  singleRecipe(id: $recipeId) {
    ...RecipeOverviewData
  }
}
  ${RECIPE_OVERVIEW_DATA}

`;
export const GET_RECIPE_INGREDIENTS_BASIC_SUGGESTIONS = gql`
query GET_RECIPE_INGREDIENTS_BASIC_SUGGESTIONS {
  ingredientsRecipeBasicSuggestions {
    ...RecipeOverviewData
  }
}
  ${RECIPE_OVERVIEW_DATA}
`;

export const GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH = gql`
query GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH($search: String) {
  searchRecipes(search: $search) {
    id
    name
    book {
        name
    }
  }
}
`;

export const GET_ALL_INGREDIENTS = gql`
query GET_ALL_INGREDIENTS {
  ingredients {
    id
    name
    recipeCount {
        total
        canMake
        lastIngredientMissing
    }
    owned
    hasBottles
  }
}
`;

// Should be equal to GET_RECIPE_INGREDIENTS_VIEW
export const ON_CAN_MAKE = gql`
  mutation ON_CAN_MAKE($id: String!) {
    canMake(id: $id) {
        id
        canMake
    }
  }
`;


// Should be equal to GET_RECIPE_INGREDIENTS_VIEW
export const ON_REMOVE_INGREDIENT = gql`
  mutation ON_REMOVE_INGREDIENT($id: String!, $ingredient: String!) {
    removeIngredient(id: $id, ingredient: $ingredient) {
        id
        canMake
    }
  }
`;

export const INVERT_INGREDIENT = gql`
  mutation INVERT_INGREDIENT($ingredient: String!) {
    invertIngredient(ingredient: $ingredient) {
        id
        recipeCount {
            total
            canMake
            lastIngredientMissing
        }
        owned
    }
  }
`;

export const LOAD_BOTTLES = gql`
  query LOAD_BOTTLES($id: String!) {
    ingredient(id: $id) {
        id
        possibleBottles {
          id
          name
          recipes {
            id
          }
        }
    }
  }
`;