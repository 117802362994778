import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_RECIPE_INGREDIENTS_VIEW} from "../../utilities/Queries";
import RecipesCard from "../../recipes/RecipesCard";
import {CircularProgress} from "@material-ui/core";
import DisplaySelectedIngredientRecipesEmpty from "./DisplaySelectedIngredientRecipesEmpty";
import {client} from "../../ApolloLayer";


export default function DisplaySelectedIngredientRecipes(props) {
    const recipeId = props.currentRecipe;
    if (recipeId === null) {
        return <DisplaySelectedIngredientRecipesEmpty/>;
    }
    const {loading, error, data} = useQuery(GET_RECIPE_INGREDIENTS_VIEW, {
        variables: {recipeId: recipeId},
        fetchPolicy: "network-only",
        client: client
    });

    if (loading) {
        return <CircularProgress color="secondary"/>;
    }
    if (error) {
        return error;
    }
    let recipe = data.singleRecipe;
    if (recipe) {
        return (
            <div className="flex-column-center">
                <div>
                    <RecipesCard key={recipe.id} recipe={recipe} isVariant={false} isIngredientsView={true}/>
                </div>
            </div>
        );
    } else {
        return <DisplaySelectedIngredientRecipesEmpty/>;
    }
}