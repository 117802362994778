import React, {Component} from "react";
import "./App.css";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {HashRouter, Route, Routes} from "react-router-dom";
import CocktailMenuExternalUserFetcher from "./cocktailmenu/external/CocktailMenuExternalUserFetcher";
import ApolloLayer from "./ApolloLayer";
import {GoogleOAuthProvider} from "@react-oauth/google";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#FFF"
        },
        secondary: {
            main: "#d4af37"
        }
    },
});

class App extends Component {

    render() {
        return <HashRouter>
            <GoogleOAuthProvider clientId="51903684772-v3bmco4sa03h5i2tiee259vh7pv69phn.apps.googleusercontent.com">
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/menu/:s" element={<CocktailMenuExternalUserFetcher/>}/>
                    <Route path="*" element={<ApolloLayer/>}/>
                </Routes>
            </ThemeProvider>
                </GoogleOAuthProvider>
        </HashRouter>;
    }
}

export default App;
