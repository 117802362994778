import React from "react";
import RecipesCard from "./RecipesCard";
import {useQuery} from "@apollo/react-hooks";
import {GET_RECIPES} from "../utilities/Queries";
import {mapFilters, mapSearchTerms} from "../utilities/Utilities";
import InfiniteScrollContainer from "../utilities/InfiniteScrollContainer";
import RecipesLoading from "./RecipesLoading";
import {client} from "../ApolloLayer";

export const LIMIT = 30;

export default function Recipes(props) {
    const {
        bookTitle,
        searchTerms,
        selectedGlasses,
        selectedBuildTypes,
        selectedTypes,
        selectedCocktailCategories,
        selectedBooks,
        minMaxIngredients,
        minMaxABV,
        minMaxAlcoholicUnits
    } = props;
    let mappedSearchTerms = mapSearchTerms(searchTerms);
    let mappedFilters = mapFilters(selectedGlasses, minMaxIngredients, selectedBuildTypes, selectedTypes, selectedCocktailCategories, selectedBooks, minMaxABV, minMaxAlcoholicUnits);
    let getRecipesVariables;
    if (bookTitle !== undefined) {
        getRecipesVariables = {
            filters: mappedSearchTerms,
            advancedFilters: mappedFilters,
            offset: 0,
            limit: LIMIT
        };
    } else {
        getRecipesVariables = {
            filters: mappedSearchTerms,
            advancedFilters: mappedFilters,
            offset: 0,
            limit: LIMIT
        };
    }

    const {loading: recipesLoading, error: recipesError, data: recipesData, fetchMore} = useQuery(GET_RECIPES,
        {
            variables: getRecipesVariables,
            client: client
        });
    if (recipesLoading) {
        return <RecipesLoading/>;
    }
    if (recipesError) {
        return recipesError;
    }
    let recipes = recipesData.newRecipes;
    let recipesLength = recipesData.newRecipesLength;
    let titleText = "Showing " + recipes.length + " out of " + recipesLength[1] + " total recipes";
    if (recipesLength[0] > 0) {
        titleText += ", of which you can make " + recipesLength[0] + " recipes";
    }
    titleText += ".";
    return (
        <div className="recipes">
            <div
                className="total-recipes">{titleText}</div>
            <InfiniteScrollContainer
                fetchMoreMainData={() => {
                    const newOffset = recipes.length;

                    fetchMore({
                        variables: {
                            ...getRecipesVariables, // Keep existing filters and limits
                            offset: newOffset // Update the offset
                        },
                        updateQuery: (prev, {fetchMoreResult}) => {
                            if (!fetchMoreResult) {
                                return prev;
                            }

                            return {
                                ...prev,
                                newRecipes: [...prev.newRecipes, ...fetchMoreResult.newRecipes]
                            };
                        }
                    });
                }}
                recipesLength={recipesLength[1]}
                key={"infinite-scroll-container"}>
                {renderRecipes(recipes, false, getRecipesVariables, undefined, props.isLoggedIn)}
            </InfiniteScrollContainer>
        </div>
    );
}

export function renderRecipes(recipes, renderVariants, getRecipesVariables, getLinkedRecipesVariables, isLoggedIn) {
    return recipes.map((recipe) => (
        <RecipesCard key={recipe.id}
                     isLoggedIn={isLoggedIn}
                     recipe={recipe}
                     isVariant={renderVariants}
                     getRecipesVariables={getRecipesVariables}
                     getLinkedRecipesVariables={getLinkedRecipesVariables}/>
    ));
}