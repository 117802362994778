import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {CheckBox, Favorite, FavoriteBorder, Info, InfoOutlined} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {GET_LINKED_RECIPES, GET_RECIPES, ON_CAN_MAKE, ON_FAVORITE, ON_REMOVE_INGREDIENT} from "../utilities/Queries";
import {Link} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {client} from "../ApolloLayer";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {getPrettyIngredientName} from "../utilities/Utilities";

const useStyles = makeStyles(theme => ({
    root2: {
        width: 300,
        marginBottom: 10,
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    header: {
        display: "block"
    },
    actions: {
        alignItems: "flex-end"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%"
    }
}));

export default function RecipeCard(props) {
    const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
    const [onFavorite] = useMutation(ON_FAVORITE, {
        client: client
    });
    const [onCanMake] = useMutation(ON_CAN_MAKE, {
        client: client
    });
    const [onRemoveIngredient] = useMutation(ON_REMOVE_INGREDIENT, {
        client: client
    });
    const classes = useStyles();
    const recipe = props.recipe;
    const isVariant = props.isVariant;
    const isIngredientsView = props.isIngredientsView;
    const getRecipesVariables = props.getRecipesVariables;
    const getLinkedRecipesVariables = props.getLinkedRecipesVariables;
    return <div onClick={isVariant ? (event) => {
        event.stopPropagation();
    } : undefined}
                className={(recipe.canMake || isVariant || isIngredientsView || !props.isLoggedIn) ? "recipe-card" : "recipe-card-cantmake"}>
        <Card className={classes.root2}>
            <CardHeader
                className={classes.header}
                title={recipe.name}
                subheader={recipe.book.name + " (" + recipe.pageNumber + ")"}
                titleTypographyProps={recipe.canMake || isIngredientsView || !props.isLoggedIn ? undefined : {style: {textDecoration: "line-through"}}}
            />
            <CardContent className={classes.content}>
                <Typography variant="body2" component="p">
                    {formatIngredients(recipe.ingredients)}
                </Typography>
            </CardContent>
            <CardActions className={classes.actions}>
                {renderViewRecipe(recipe)}
                {(!isIngredientsView && props.isLoggedIn) && <div>
                    <Tooltip title={recipe.favorite ? "Remove from favorites" : "Add to favorites"}>
                        <IconButton color="secondary"
                                    aria-label="Add to favorites"
                                    onClick={() => {
                                        onFavorite({
                                            variables: {
                                                bookName: recipe.book.name,
                                                id: recipe.id,
                                                favorite: !recipe.favorite
                                            },
                                            optimisticResponse: {
                                                __typename: "Mutation",
                                                newSetFavorite: {
                                                    __typename: "NewRecipe",
                                                    id: recipe.id,
                                                    favorite: !recipe.favorite
                                                }
                                            }
                                        });
                                    }}
                        >
                            {recipe.favorite ? <Favorite/> : <FavoriteBorder/>}
                        </IconButton>
                    </Tooltip>
                </div>}
                {(isIngredientsView || (getRecipesVariables && props.isLoggedIn)) &&
                    <Tooltip
                        title={recipe.canMake ? "I don't have all of these ingredients" : "I have all of these ingredients"}>
                        <IconButton aria-label="I have all of these ingredients"
                                    color={recipe.canMake ? "secondary" : undefined}
                                    onClick={() => {
                                        if (recipe.canMake) {
                                            setRemoveDialogOpen(true);
                                        } else {
                                            let options = {variables: {id: recipe.id}};
                                            let refetchedQueries = [];
                                            if (getRecipesVariables) {
                                                refetchedQueries.push({
                                                    query: GET_RECIPES,
                                                    variables: getRecipesVariables
                                                });
                                            }
                                            if (getLinkedRecipesVariables) {
                                                refetchedQueries.push({
                                                    query: GET_LINKED_RECIPES,
                                                    variables: getLinkedRecipesVariables
                                                });
                                            }
                                            if (refetchedQueries.length > 0) {
                                                options.refetchQueries = refetchedQueries;
                                            }
                                            onCanMake(options);
                                        }
                                    }}
                        >
                            {<CheckBox/>}
                        </IconButton>
                    </Tooltip>}
            </CardActions>
        </Card>
        {isRemoveDialogOpen && <Dialog onClose={() => {
            setRemoveDialogOpen(false);
        }} open={true}>
            <DialogTitle>Remove ingredient</DialogTitle>
            <List>
                {recipe.ingredients.map((ingredient) => (
                    <ListItem button onClick={() => {
                        let options = {
                            variables: {id: recipe.id, ingredient: ingredient.name},
                            optimisticResponse: {
                                __typename: "Mutation",
                                removeIngredient: {
                                    __typename: "Recipe",
                                    id: recipe.id,
                                    canMake: false
                                }
                            }
                        };
                        if (getRecipesVariables) {
                            options.refetchQueries = [{
                                query: GET_RECIPES,
                                variables: getRecipesVariables
                            }];
                        }
                        onRemoveIngredient(options);
                        setRemoveDialogOpen(false);
                    }} key={ingredient.id}>
                        <ListItemText primary={ingredient.name}/>
                    </ListItem>
                ))}
            </List>
        </Dialog>}
    </div>;
}

function formatIngredients(ingredients) {
    let asArray = ingredients.map(
        (ingredient) => {
            let formatted = getPrettyIngredientName(ingredient.name);
            formatted = formatted.toLowerCase();
            formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);
            return formatted + " | ";
        }
    );
    if (asArray.length > 0) {
        asArray[asArray.length - 1] = asArray[asArray.length - 1].substr(0, asArray[asArray.length - 1].length - 3);
    } else {
        return "No ingredients in this recipe.";
    }
    let returned = "";
    asArray.forEach(
        (element) => {
            returned += element;
        }
    );
    return returned;
}

export function combineLinkedRecipesWithThis(recipe) {
    let displayedRecipes = JSON.parse(JSON.stringify(recipe.linkedRecipes));
    displayedRecipes.unshift(recipe);
    return displayedRecipes;
}

export function getLink(recipe) {
    return "/book/" + recipe.book.formattedName + "/" + recipe.name.split(" ").join("_").split("#").join("|x|");
}

function calculateBasicName(sameBookRecipes) {
    return sameBookRecipes[0].name.split("(")[0].split(" ").join("");
}

export function getLinkedRecipeTitle(linkedRecipe, displayedRecipes) {
    let name = linkedRecipe.book.name;
    let sameBookRecipes = displayedRecipes.filter((displayedRecipe) => {
        return displayedRecipe.book.name === linkedRecipe.book.name;
    });
    if ((sameBookRecipes).length > 1) {
        let basicName = calculateBasicName(sameBookRecipes);
        let split = linkedRecipe.name.split(" ").join("").split(basicName);
        if (split.length > 1 && split[1].length > 0) {
            let innerSplit = linkedRecipe.name.split("(");
            if (innerSplit.length > 1) {
                name += " (" + innerSplit[1];
            } else {
                name += " " + split[1].trim();
            }
        }
    }
    return name;
}

function renderViewRecipe(recipe) {
    const [anchorEl, setAnchorEl] = useState(null);


    function handleClose() {
        setAnchorEl(null);
    }

    if (recipe.linkedRecipes.length > 0) {
        let displayedRecipes = combineLinkedRecipesWithThis(recipe);
        return <React.Fragment key={recipe.id}>
            <Tooltip title="View recipe">
                <IconButton color="secondary" aria-label="View recipe" onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}>
                    <Info/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                key={"menu-recipe" + recipe.id}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {displayedRecipes.map((linkedRecipe, i) => {
                    let name = getLinkedRecipeTitle(linkedRecipe, displayedRecipes);
                    return (
                        <Link style={{textDecoration: "none", color: "inherit"}} key={recipe.id + i}
                              to={getLink(linkedRecipe)}>
                            <MenuItem onClick={() => {
                            }}>
                                {name}
                            </MenuItem>
                        </Link>
                    );
                })}
            </Menu>
        </React.Fragment>;
    } else {
        return <Link to={getLink(recipe)}>
            <Tooltip title="View recipe">
                <IconButton color="secondary" aria-label="View recipe">
                    <InfoOutlined/>
                </IconButton>
            </Tooltip>
        </Link>;
    }
}